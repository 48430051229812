import {httpPost} from "@/utils/fetch";

// 发起投诉
export const sendComplaint = (params) => {
    return httpPost('/complaint/sendComplaint',params)
}
// 我的投诉列表
export const getComplaintList = (params) => {
    return httpPost('/complaint/complaintPage',params)
}
// 投诉详情
export const getComplaintDetails = (params) => {
    return httpPost('/complaint/getComplaintDetails',params)
}