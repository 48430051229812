<template>
    <!-- 公告详情 -->
    <div class="contract-complaint">
        <div class="main">
            <div class="title">投诉内容</div>
            <div class="text">
                <textarea
                    placeholder="请输入投诉内容 ~"
                    @input="countTextNum"
                    v-model="textVal"
                    maxlength="500"
                ></textarea>
                <div class="text-num color-999">{{ textNum }}/500</div>
            </div>
            <div class="btn flex">
                <button class="cancel" @click="cancelComplaint">
                    取消投诉
                </button>
                <button class="confirm color-fff" @click="handleTextConfirm">
                    确认投诉
                </button>
            </div>
        </div>
        <complaintConfirm
            :isShow="confirmModal"
            @cancel="handleConfirmCancel"
        />
    </div>
</template>

<script>

import { seeNoticeSystem } from "@/api/message";
import complaintConfirm from "@/components/mine/complaintConfirm.vue";
import { sendComplaint } from "@/api/complaint";
export default {
    components: {
        complaintConfirm,
    },
    data() {
        return {
            headerTitle: "投诉",
            backVisible: true,
            // describe:`<p style='color:#222;line-height:26px'>平台通知内容平台通知内容平台
            // 		通知内容平台通知内容平台通知内容平台通知内容平台通知内容</p>`,
            detailObj: {},
            textNum: 0,
            textVal: "",
            id: "",
            confirmModal: false,
        };
    },
    methods: {
        countTextNum() {
            let len = this.textVal.length;
            this.textNum = len;
        },
        cancelComplaint() {
            // wx.navigateBack({
            //     delta: 1,
            // });
            this.$router.go(-1);
        },
        handleConfirmCancel(){
            this.confirmModal = false;
        },
        handleTextConfirm() {
            let data = {
                contractId: this.id,
                content: this.textVal,
            };
            if (!data.content) {
                this.$toast({
                    message: "合同内容不能为空",
                })
                return;
            }
            sendComplaint(data).then((res) => {
                let { status, statusMessage } = res;
                this.textVal = "";
                this.textNum = 0;
                if (status) {
                    this.confirmModal = true;
                    setTimeout(() => {
                        this.confirmModal = false;
                        this.$router.go(-1);
                    }, 3000);
                } else {
                    
                    setTimeout(()=>{
                        this.$toast({
                            message: statusMessage,
                        })
                    },300)            
                    return;
                }
            });
        },
    },
    created() {
        let id = this.$route.query.id;
        this.id = id;
    },
};
</script>

<style scoped>
div {
    box-sizing: border-box;
}
.contract-complaint {
    padding-top: 50px;
}
.contract-complaint .main {
    padding: 20px 20px;
}

.contract-complaint .main .title {
    font-weight: 600;
}

.contract-complaint .main .text {
    margin-top: 20px;
    position: relative;
}

.contract-complaint .main .text textarea {
    box-sizing: border-box;
    width: 336px;
    height: 265px;
    background: #fbfbfb;
    border-radius: 10px;
    padding: 18.5px 15px 40px 15px;
    color: #999;
    font-size: 15px;
    border: none;
}

.contract-complaint .main .text-num {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 12px;
}

.contract-complaint .main .btn {
    margin-top: 40px;
}

.contract-complaint .main .btn button {
    width: 160px;
    height: 44px;
    border-radius: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    border: none;
}

.contract-complaint .main .btn .cancel {
    color: #4c5fb4;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(124, 138, 194, 0.14);
}

.contract-complaint .main .btn .confirm {
    margin-left: 13px;
    background: #4d62b8;
    box-shadow: 0px 4px 12px 0px rgba(124, 138, 194, 0.14);
}

.contract-complaint .main .btn button:active {
    opacity: 0.8;
}

.contract-complaint .main .btn button:focus {
    opacity: 0.8;
}
</style>
